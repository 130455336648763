<template>
  <div>
    <win-layer-pop class="common-booking">
      <component
        :is="commonBkgComponent"
        v-if="commonBkgComponent"
        :parent-info="commonBkgPopParams"
        @close="commonBkgClosePopup"
        @selectFunc="commonBkgSelectFunc"
      />
    </win-layer-pop>
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'
import commons from '@/api/services/commons'
import bookings from '@/api/rest/trans/bookings'
import schedule from '@/api/rest/schedule/schedule'
import trans from '@/api/rest/trans/trans'
import docs from '@/api/rest/trans/docs'
import srInquiry from '@/api/services/srInquiry'

export default {
  name: 'CommonBooking',
  components: {
    BookingCancelPop: () => import('@/pages/trans/popup/BookingCancelPop'),
    SHASRCancelPop: () => import('@/pages/trans/popup/SHASRCancelPop'),
    BookingCancelFeePop: () => import('@/pages/trans/popup/BookingCancelFeePop'),
    BookingCancelRequestPop: () => import('@/pages/trans/popup/BookingCancelRequestPop'),
    BookingCombineDetailPop: () => import('@/pages/trans/popup/BookingCombineDetailPop'),
    BookingSplitDetailPop: () => import('@/pages/trans/popup/BookingSplitDetailPop'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: () => {
        return {
          action: '',
          lang: ''
        }
      }
    }
  },
  data () {
    return {
      commonBkgComponent: null,
      commonBkgPopParams: {},
      commonBkgSelectFunc: null
    }
  },
  // watch: {
  //   parentInfo () {
  //     this.doAction()
  //   }
  // },
  computed: {
    ...rootComputed
  },
  created () {
    this.doAction()
  },
  methods: {
    doAction () {
      this.lang = this.auth.serviceLang

      const params = {
        bkgNo: this.$props.parentInfo.bkgNo,
        srRno: this.$props.parentInfo.srRno,
        polPortCd: this.$props.parentInfo.polPortCd,
        cancelCd: this.$props.parentInfo.cancelCd
      }

      if (this.$props.parentInfo.action === 'CANCEL') {
        //상해 SR+BOOKING 전용 부킹 취소
        if (this.memberDetail.bkgPlcCd === 'SHA' || this.memberDetail.bkgPlcCd === 'SZP' || this.auth.serviceLang === 'CHN') {
          this.commonSHASRCancel(params)
        } else {
          this.commonBkgCancel(params)
        }
      } else if (this.$props.parentInfo.action === 'SPLIT') {
        this.commonBkgSplitCombine(params, 'SPLIT')
      } else if (this.$props.parentInfo.action === 'COMBINE') {
        this.commonBkgSplitCombine(params, 'COMBINE')
      }
    },
    //상해 SR+BOOKING 전용 부킹 취소
    async commonSHASRCancel (params) {
      console.log('@@@@ enter commonSHASRCancel: ', params)
      await srInquiry.getSrInqueryReqInfo(params.srRno).then(res => {
        const result = res.data
        params = result
      })

      const arrPortCd = ['SHA', 'TAO', 'SZP', 'DCB', 'YTN', 'SHK', 'NBO', 'XGG', 'XMN']
      let isCancelPop = false
      if (arrPortCd.includes(params.polPortCd)) {
          if (params.cancelCd !== '99') {
            alert('退关操作不可用, 请联系订舱组负责人')
            return
          }
          if (params.polPortCd === arrPortCd[0]) isCancelPop = true
      }
      this.$ekmtcCommon.confirmCallBack(this.$t('tem.CMAT023'), () => {
        this.shaSRCancelFnc(params, isCancelPop)
      })
    },
    async shaSRCancelFnc (vo, isFlag) {
      console.log('@@@@ enter shaSRCancelFnc')
      console.log('@@@@ vo: ', vo)
      console.log('@@@@ isFlag: ', isFlag)
      if (isFlag) {
        this.commonSHASRCancelRequestPop(vo)
      } else {
        //S/R Cancel 시작
        const resultData = await srInquiry.putSrCancel(vo)
        //취소 결과에 따른 메세지
        if (resultData.data.cancelResult === 'Y') {
          await this.$ekmtcCommon.asyncAlertDefault(this.$t('tem.CMAT015')) //적용되었습니다.
          // this.$router.push('/working/progress')
          this.commonBkgCancelCallback()
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P070.018')) //오류가 발생하였습니다.
        }
      }
    },
    // 부킹 취소 (시작)
    async commonBkgCancel ({ bkgNo }) {
      const bkgPlcCd = this.memberDetail.bkgPlcCd
      let localDtm = ''
      let serverDt = ''
      let cgoRest = ''
      let regParams = {}

      await commons.getServerTime().then(res => {
        if (res.data !== undefined) {
          serverDt = res.data.length > 12 ? res.data.substring(0, 12) : res.data
        }
      })

      await bookings.getBooking(bkgNo).then(async res => {
        const result = res.data
        regParams = result
        localDtm = result.localDtm
      })

      if (regParams.mtyPkupUsagYn !== 'Y') {
        const params = {
          asBkgNo: regParams.bkgNo,
          asVoyNo: regParams.voyNo,
          asVslCd: regParams.vslCd,
          asPolPortCd: regParams.polPortCd,
          asPodPortCd: regParams.podPortCd
        }

        let searchFlag = true

        await Promise.all([
          schedule.checkBkgRestriction(params).then(res1 => { // 부킹 제약사항 별도 조회
            const restriction = res1.data
            if (restriction !== undefined && restriction.msg === 'Y') {
              cgoRest = restriction.cgoRest
            } else {
              searchFlag = false
            }
          }).catch((err) => {
            console.log(err)
            searchFlag = false
          })
        ])

        if (!searchFlag) {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
          return
        }
      }

      if (this.lang === 'KOR') {
        const localDate = this.$ekmtcCommon.getStrToDate(localDtm)
        let notiMsg = ''
        regParams.containerList.forEach(c => {
          if ((c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') &&
              this.$ekmtcCommon.isNotEmpty(c.pcupReqDt) &&
              c.pcupReqDt.length === 8) {
            const getDate = this.$ekmtcCommon.getStrToDate(c.pcupReqDt)

            if (getDate <= localDate) {
              notiMsg = '부킹 취소는 부킹사무소별 담당자와 확인 후 처리 부탁드립니다.'
              if (bkgPlcCd === 'SET') {
                notiMsg += '(서울: 02-311-6114 내선 1번)'
              } else if (bkgPlcCd === 'PUS') {
                notiMsg += '(부산: 051-461-2800 내선 1번)'
              }
            }
          }
        })

        if (notiMsg !== '') {
          this.$ekmtcCommon.alertDefault(notiMsg)
          return
        }
      }

      let delegate = true
      // 서류마감 여부 서류마감일이 오늘날짜보다 작을때로 변경해야함
      if (this.$ekmtcCommon.isNotEmpty(cgoRest) && Number(cgoRest) < Number(serverDt)) {
        const reqParam = {
          bkgNo: regParams.bkgNo,
          reqCatCd: '01'
        }

        let succFlag = true
        await docs.findReqChk(reqParam).then(res => {
          const result = res.data

          if (result !== undefined) {
            const arrResult = result.split('@@')
            const flag = arrResult[0]

            if (flag === 'Y') {
              this.commonBkgCancelRequestPop(regParams, delegate)
            } else {
              this.$ekmtcCommon.alertDefault(arrResult[1])
            }
          } else {
            succFlag = false
          }
        }).catch(() => {
          succFlag = false
        }).finally(() => {
          if (!succFlag) {
            this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
          }
        })
      } else {
        let emptyShip = false
        await bookings.getContainerLog({ bkgNo: regParams.bkgNo, cntrStsCd: 'GTO' }).then(res => {
          if (res.headers.respcode === 'C0000' && res.data !== undefined && res.data.length > 0) {
            emptyShip = true
          }
        }).catch(() => {
        }).finally(async () => {
          if (emptyShip) {
            if (this.lang !== 'JPN') {
              this.commonBkgCancelFeePop(regParams)
            } else {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.405'))
            }
          } else {
            await this.commonBkgFnCancel(regParams)
          }
        })
      }
    },
    async commonBkgFnCancel (regParams) {
      if (this.lang === 'KOR') {
        await trans.findCnclClosePopYn(regParams.bkgNo).then(async res => {
          const result = res.data
          if (result === 'Y') {
            this.commonBkgCancelPop(regParams)
          } else {
            await this.commonBkgSaveCancel(regParams)
          }
        })
      } else {
        const usrCtrCd = this.auth.userCtrCd
        let isCheck = false

        if (usrCtrCd === 'TH') {
          await trans.findCnclChargeYn(regParams.bkgNo).then(async res => {
            const result = res.data

            if (result === 'Y') {
              if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.246'), useConfirmBtn: true })) {
                isCheck = true
              }
            } else {
              isCheck = true
            }
          })
        } else {
          isCheck = true
        }

        if (isCheck) {
          await this.commonBkgSaveCancel(regParams)
        }
      }
    },
    commonSHASRCancelRequestPop (regParams, delegate) {
      this.commonBkgPopParams = regParams
      this.commonBkgSelectFunc = ''
      this.commonBkgOpenPopup('SHASRCancelPop')
    },
    commonBkgCancelRequestPop (regParams, delegate) {
      this.commonBkgPopParams = {
        bkgNo: regParams.bkgNo,
        delegateYn: delegate ? 'Y' : 'N'
      }
      this.commonBkgSelectFunc = this.commonBkgCancelCallback
      this.commonBkgOpenPopup('booking-cancel-request-pop')
    },
    commonBkgCancelPop (regParams) {
      this.commonBkgPopParams = {
        bkgNo: regParams.bkgNo,
        polShtmCd: regParams.polShtmCd
      }
      this.commonBkgSelectFunc = this.commonBkgCancelCallback
      this.commonBkgOpenPopup('booking-cancel-pop')
    },
    commonBkgCancelFeePop (regParams) {
      this.commonBkgPopParams = {
        bkgNo: regParams.bkgNo
      }

      this.commonBkgSelectFunc = ''
      this.commonBkgOpenPopup('booking-cancel-fee-pop')
    },
    async commonBkgSaveCancel (regParams) {
      const THIS = this
      let successFlag = true
      const isOk = await this.$ekmtcCommon.asyncAlertMessage({ message: 'Booking ' + this.$t('tem.CMAT030'), useConfirmBtn: true })
      if (!isOk) {
        return
      }

      await bookings.bookingCancel({ bkgNo: regParams.bkgNo, cboTerm: regParams.polShtmCd }).then(res => {
        const result = res.data
        if (result === undefined || Number(result.result) !== 1) {
          successFlag = false
        }
      }).catch(() => {
        successFlag = false
      }).finally(() => {
        let returnMsg = successFlag ? THIS.$t('msg.CSBK100.384') : THIS.$t('msg.CSBK100.385')
        this.$ekmtcCommon.alertCallback(returnMsg, function () {
          THIS.commonBkgCancelCallback()
        })
      })
    },
    commonBkgCancelCallback () {
      this.$emit('callback', 'succ')
    },
    async commonBkgSplitCombine (params, flag) {
      // 한국어만 가능
      // if (this.lang !== 'KOR') {
      //   return
      // }

      let successFlag = true
      let result = ''
      await trans.findBkgSplitStatus(params.bkgNo).then(res => {
        if (res.headers.respcode !== 'C0000') {
          successFlag = false
        } else {
          result = res.data
        }
      }).catch(() => {
        successFlag = false
      }).finally(async () => {
        if (successFlag && this.$ekmtcCommon.isNotEmpty(result)) {
          if (flag === 'SPLIT') {
            await this.commonBkgSplitBooking(params, result)
          } else {
            await this.commonBkgCombineBooking(params, result)
          }
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
        }
      })
    },
    async commonBkgSplitBooking (params, result) {
      if (result !== '99' && result !== '09') {
        let failMsg = params.bkgNo + this.$t('msg.CSBK100.341')
        failMsg += this.$t('msg.CSBK100.split_fail_' + result)
        this.$ekmtcCommon.alertDefault(failMsg)
      } else {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: params.bkgNo + this.$t('msg.CSBK100.342'), useConfirmBtn: true })) {
          this.commonBkgOpenSplitCombinePop(params, 'SPLIT')
        }
      }
    },
    async commonBkgCombineBooking (params, result) {
      let regParams = {}

      await bookings.getBooking(params.bkgNo).then(async res => {
        const result = res.data
        regParams = result
      })

      const bkgStsCd = regParams.bkgStsCd

      let failMsg = regParams.bkgNo + this.$t('msg.CSBK100.343')
      let isFail = false

      if (bkgStsCd !== '01' && this.lang === 'KOR') {
        failMsg += this.$t('msg.CSBK100.combine_fail_00')
        isFail = true
      }

      if (result === '03' || result === '05' || result === '06' || result === '01') {
        failMsg += this.$t('msg.CSBK100.combine_fail_' + result)
        isFail = true
      }

      if (isFail) {
        this.$ekmtcCommon.alertDefault(failMsg)
        return
      }

      if (await this.$ekmtcCommon.asyncAlertMessage({ message: regParams.bkgNo + this.$t('msg.CSBK100.344'), useConfirmBtn: true })) {
        this.commonBkgOpenSplitCombinePop(params, 'COMBINE')
      }
    },
    commonBkgOpenSplitCombinePop (params, flag) {
      const compNm = flag === 'SPLIT' ? 'booking-split-detail-pop' : 'booking-combine-detail-pop'
      this.commonBkgPopParams = {
        bkgNo: params.bkgNo
      }

      this.commonBkgSelectFunc = this.splitCombineCallback
      this.commonBkgOpenPopup(compNm)
    },
    splitCombineCallback () {
      this.$emit('callback')
    },
    commonBkgOpenPopup (compNm) {
      console.log('----->', compNm)
      this.commonBkgComponent = compNm
      this.$ekmtcCommon.layerOpen('.common-booking')
    },
    commonBkgClosePopup () {
      this.commonBkgComponent = null
      this.commonBkgSelectFunc = null
      this.$ekmtcCommon.layerClose('.common-booking')
    }
  }
}

</script>
